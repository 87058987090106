<template>
  <div>
    {{fact}}
  </div>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";
import ClickToEdit from "@/components/ClickToEdit";

export default {
  name: "design-card-fact-view",
  mixins: [dynamicScaleComponent],
  components: {ClickToEdit},

  props: {
    disabled:{type: Boolean, default: false}
  },

  computed:{
    fact(){
      if( !this.scale.fact ){
        return null;
      }
      if( !this.scale.scopes ){
        return null;
      }
      const scope = this.scale.scopes.find(s => s.id===this.scale.fact)

      return scope.target;
    }
  },

  data() {
    return {
      scale:{
        scopes: null,
        fact: null,
      },
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style>

</style>